import { createStore } from 'vuex';

import sidebar from '@/modules/Sidebar';
//

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    sidebar,
  },
});
