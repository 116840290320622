// import router from '@/Router';
export default {
  namespaced: true,
  state: {
    openSidebar: false,
  },
  mutations: {
    SET_SIDEBAR(state, payload) {
      state.openSidebar = payload;
    },
  },
  actions: {
    setSidebar({ commit, state }) {
      commit('SET_SIDEBAR', !state.openSidebar);
    },
    hiddenSidebar({ commit }) {
      commit('SET_SIDEBAR', false);
    },
  },
};
