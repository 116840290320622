<template>
  <div>
    <BaseSidebar> <BarOperative/> </BaseSidebar>
    <div class="main-app">
      <router-view>
      </router-view>
    </div>
    <div @click="redirect()" class="contact-whatsapp-dev">
        <img :src="`${url}images/shared/whatsapp.png`"  alt="">
    </div>
  </div>
</template>
<script>
import BaseSidebar from '@/components/Bar/BaseSidebar.vue';
import BarOperative from '@/components/Bar/BarOperative.vue';
import baseUrl from '@/services/Api/BaseUrl';

export default {
  data() {
    return {
      url: baseUrl.apiDevuelvoya,
    };
  },
  components: {
    BaseSidebar,
    BarOperative,
  },
  methods: {
    redirect() {
      const text = 'https://api.whatsapp.com/send?phone=+5491138622964&text=Hola%20Devuelvoya%20';
      window.open(text, '_blank');
    },
  },
};

</script>

<style>
@import './assets/css/main.css';

.main-app {
  transition: all 0.1s;
}
.contact-whatsapp-dev{
  position: fixed !important;
  bottom:10px;
  right:10px;
  z-index: 1000;
  width:60px;
}
</style>
