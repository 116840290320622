<template>
    <div class="my-2 custom-bar" >

        <div class="ml-4 my-4 flex flex-row justify-between">
          <img class="w-28 " :src="BaseUrl + 'images/logo/express.png'" />
          <div @click="setSidebar()" class="mr-5 cursor-pointer">
              <font-awesome-icon :class="[`c-text-${iconColor}`]" icon="bars" />
          </div>
        </div>
        <LisItemFine customClass="my-1" :fullRoute="{name:'Home'}" icon="home" :text="$t('Inicio')"/>
        <LisItemFine customClass="my-1" :fullRoute="{name:'Logistica'}" icon="truck" :text="$t('Logistica inversa')"/>
        <LisItemFine customClass="my-1" :fullRoute="{name:'Distribucion'}" icon="truck-loading" :text="$t('Distribución')"/>
        <LisItemFine customClass="my-1" :fullRoute="{name:'Devolver'}" icon="paper-plane" :text="$t('Devolver equipo')"/>
        <LisItemFine customClass="my-1"  :href="`trabajar`" icon="user" :text="$t('Registrarme')"/>
        <LisItemFine customClass="my-1"  :href="`ingresar`" icon="sign-in-alt" :text="$t('Ingresar')"/>
        <LisItemFine customClass="my-1" :fullRoute="{name:'Contacto'}" icon="phone" :text="$t('Contacto')"/>

    </div>
</template>

<script>

import LisItemFine from '@/components/List/ListItemFine.vue';

import { useStore } from 'vuex';
import baseUrl from '@/services/Api/BaseUrl';

export default {
  components: {
    LisItemFine,
  },
  props: {
    iconColor: {
      type: String,
      default: 'gray',
    },
    textColor: {
      type: String,
      default: 'black',
    },
  },
  setup() {
    const BaseUrl = baseUrl.apiDevuelvoya;
    const store = useStore();
    const setSidebar = () => {
      store.dispatch('sidebar/setSidebar', false);
    };
    return {
      BaseUrl, setSidebar,
    };
  },
};
</script>
