import { createRouter, createWebHistory } from 'vue-router';

const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue');
const Devolver = () => import(/* webpackChunkName: "home" */ '@/views/Devolver.vue');
const Logistica = () => import(/* webpackChunkName: "home" */ '@/views/Logistica.vue');
const Distribucion = () => import(/* webpackChunkName: "home" */ '@/views/Distribucion.vue');
const Contacto = () => import(/* webpackChunkName: "home" */ '@/views/Contacto.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/logistica',
    name: 'Logistica',
    component: Logistica,
  },
  {
    path: '/distribucion',
    name: 'Distribucion',
    component: Distribucion,
  },
  {
    path: '/devolver',
    name: 'Devolver',
    component: Devolver,
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
