<template>
    <div :class="customClass">
        <template v-if="link && !href">
            <router-link style="text-decoration: none;" :active-class="`c-bg-${isActive}`" :to="fullRoute" :class="['flex flex-row',`${height}`, 'items-center', 'list']">
                <div>
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                </div>
                <div>
                    <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                </div>
            </router-link>
        </template>
        <template v-if="href">
            <div @click="redirect(href)" :active-class="`c-bg-${isActive}`" :class="['flex flex-row',`${height}`, 'items-center', 'list']">
                <div>
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                </div>
                <div>
                    <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                </div>
            </div>
        </template>
        <template v-if="multiLink && !href">
          <div class="flex flex-col">
            <div v-if="topLine" >
                <hr style="height:1px;background-color:#646060;border:none">
            </div>
            <div @click="multiShow = !multiShow" :class="['flex flex-row justify-between items-center cursor-pointer', 'list']">
              <div  :class="['flex flex-row',`${height}`, 'items-center']">
                  <div>
                      <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="icon" />
                  </div>
                  <div>
                      <span :class="[`c-text-${textColor}`,'ml-2','tracking-tighter']">{{text}}</span>
                  </div>
              </div>
              <div  class="mr-2 cursor-pointer">
                    <font-awesome-icon :class="[`c-text-${iconColor}`, 'ml-4']" :icon="multiShow? 'arrow-down' : 'arrow-up'" />
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div  v-if="multiShow" >
                  <div v-for="({text,name},index) in fullRoutes" :key="index">
                    <router-link :active-class="`c-bg-${isActive}`" :to="{name}" :class="['flex flex-row',`${height}`, 'items-center', 'cursor-pointer', 'list']">
                      <div class="ml-6 sm:ml-8">
                          <span :class="[`c-text-${textColor}`,'tracking-tighter']">{{text}}</span>
                      </div>
                    </router-link>
                  </div>
              </div>
            </transition>
            <div v-if="bottomLine" >
                <hr style="height:1px;background-color:#646060;border:none">
            </div>
          </div>

        </template>

    </div>
</template>
<script>

export default {
  props: {
    link: {
      type: Boolean,
      default: true,
    },
    href: {
      type: String,
      default: '',
    },
    fullRoute: {
      type: Object,
      default() {
        return {};
      },
    },
    fullRoutes: {
      type: Array,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: 'gray',
    },
    textColor: {
      type: String,
      default: 'blue',
    },
    text: {
      type: [String, Array, Object],
      required: true,
    },
    height: {
      type: String,
      default: 'h-10',
    },
    isActive: {
      type: String,
      default: 'gray-active',
    },
    hover: {
      type: String,
      default: 'rgba(255,255,255,.16)',
    },
    multiLink: {
      type: Boolean,
      default: false,
    },
    topLine: {
      type: Boolean,
      default: false,
    },
    bottomLine: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      multiShow: false,
    };
  },
  methods: {
    redirect(url) {
      document.location.href = `${process.env.VUE_APP_CLIENT}${url}`;
    },
  },

};
</script>

<style scoped>

  .list:hover {
    background:v-bind('hover');
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s, transform 0.2s;
}

.fade-enter-from, .fade-leave-to{
    opacity:0.1;

}
</style>
