<template>
  <div :class="['navbar shadow-sm', `c-bg-${background}`,
   `c-text-${titleColor}`, 'border-b border-gray']">
   <div class="flex flex-row">
     <template v-if="back">
        <router-link :to="backPath" class="ml-4">
          <font-awesome-icon :class="[`c-text-${iconColor}`]"  icon="arrow-left" />
        </router-link>
     </template>
     <template v-if="activeBar && activeBarStart">
        <template v-if="!store.state.sidebar.openSidebar">
          <div @click="setBar()" class="ml-2 cursor-pointer">
            <font-awesome-icon :class="[`c-text-${iconColor}`]" icon="bars" />
          </div>
        </template>
     </template>
    <div :class="titleClass">
        {{section}}
    </div>
    <div class="flex flex-row items-center ml-2 cursor-pointer">
      <div @click="changeLocale('es')" class="mr-2" style="width: 25px;">
        <img :src="spainIcon" alt="Spain Flag">
      </div>
      <div @click="changeLocale('pr')" class="mr-2" style="width: 25px;">
        <img :src="brasilIcon" alt="Spain Flag">
      </div>
    </div>
    <div>

    </div>
   </div>
   <template v-if="activeBar && !activeBarStart">
    <div @click="setBar()" class="mr-4 cursor-pointer">
      <font-awesome-icon :class="[`c-text-${iconColor}`]" icon="bars" />
    </div>
   </template>
   <div class="logo-img-dev">
   <img class="w-28 " :src="`${$apiDevuelvoya}images/logo/express.png`" />
   </div>
  </div>
</template>

<script setup>

import { toRefs, getCurrentInstance} from 'vue'
import { useI18n,  } from 'vue-i18n';
import { useStore } from 'vuex';

import spainIcon from '@/assets/images/spain.png'
import brasilIcon from '@/assets/images/brasil.png'

const store = useStore();

const { t, locale } = useI18n();

const changeLocale = (newLocale) => {
  locale.value = newLocale
  console.log(locale.value)
  // t.setLocale(locale);
};

const props = defineProps({
    // Nombre de la seccion
    section: String,
    // El objeto de la ruta a redirigir
    backPath: Object,
    // Clase del titulo
    titleClass: String,
    background: {
      type: String,
      default() {
        return 'white';
      },
    },
    titleColor: {
      type: String,
      default() {
        return 'black';
      },
    },
    iconColor: {
      type: String,
      default() {
        return 'gray';
      },
    },
    activeBar: {
      type: Boolean,
      default() {
        return true;
      },
    },
    activeBarStart: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // Indicar si el navbar tendra boton de retorno
    back: {
      type: Boolean,
      default() {
        return false;
      },
    },

})

const { 
  section,
  background,
  titleColor,
  back,
  backPath,
  iconColor,
  activeBar,
  activeBarStart,
  titleClass
} = toRefs(props);

const setBar = () => store.dispatch('sidebar/setSidebar');

</script>

<style scoped>
.navbar {
    padding:5px 5px;
    height:72px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}
.logo-img-dev{
  width:100px;
  position:relative;
  left:-20px;
}

</style>
